<template>
  <section>
    <div class="my-4">
      <div class="flex justify-between">
        <h2 class="text-base font-600">
          application ledger
        </h2>
        <h2 class="flex text-base font-600">
          current balance
          <p class="text-blue-500 ml-2">{{application.balance}}</p>
        </h2>
      </div>
      <div v-if="application.payments.length">
        <list
          title="payments"
          :basePath="basePath"
          :fields="fields"
          track-by="paymentId"
          ref="list"
          on-row-click="edit"
          :data="application.payments" />
      </div>
      <div v-else-if="!application.payments.length" class="w-full">
        <p class="text-center my-4">No data available</p>
      </div>
    </div>
  </section>
</template>
<script>
import DateTimeField from '@/components/auth/list/fields/DateTimeField';
import List from '@/components/application_service/List.vue';
export default {
  components: { List },
  computed: {
    basePath: function () {
      return this.$route.path;
    },
  },
  props: {
    application: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      fields: [
        {
          title: 'posted',
          name: DateTimeField,
          sortField: 'posted',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'posted',
            showTime: false,
          }
        },
        {
          title: 'due',
          name: DateTimeField,
          sortField: 'due',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'due',
            showTime: false,
          }
        },
        {
          name: 'charge_code',
          sortField: 'charge_code',
          title: 'charge code',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'ref_number',
          sortField: 'ref_number',
          title: 'ref number',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'last_modified',
          sortField: 'last_modified',
          title: 'modified by',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'payment',
          sortField: 'payment',
          title: 'payment',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'charge',
          sortField: 'charge',
          title: 'charge',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'balance',
          sortField: 'balance',
          title: 'balance',
          titleClass: 'text-center',
          dataClass: 'text-center',
        }
      ]
    }
  },
}
</script>