<template>
  <section>
    <List 
      title="records"
      :basePath="basePath"
      :request-params="{deletedOnly: true}"
      :fields="fields"
      track-by="recordId"
      ref="list"
      on-row-click="edit"
      :data="data"
     />
  </section>
</template>
<script>
import DateTimeField from '@/components/auth/list/fields/DateTimeField';
import List from '@/components/application_service/List.vue';
export default {
  computed: {
    basePath: function () {
      return this.$route.path;
    },
  },
  components: {
    List,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      fields: [
        {
          title: 'date',
          name: DateTimeField,
          sortField: 'date',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'date',
            showTime: true,
          }
        },
        {
          name: 'activity',
          sortField: 'activity',
          title: 'activity',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: 'user',
          sortField: 'user',
          title: 'user',
          titleClass: 'text-center',
          dataClass: 'text-center',
        }
      ]
    }
  },
}
</script> 