<template>
  <section>
    <section v-if="currentApplicant.role === 'applicant'">
      <div class="my-4">
        <div class="w-full flex">
          <div class="flex w-4/5 grid grid-cols-3 gap-4">
            <div>
              <label class="text-sm text-gray-500 font-500" for="name">name</label>
              <p class="font-400">{{`${currentApplicant.firstName} ${currentApplicant.lastName}`}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="phone">phone</label>
              <p class="font-400">{{currentApplicant.phone}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="email">email</label>
              <p class="font-400">{{currentApplicant.email}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="status">applicant status</label>
              <p class="font-400">{{currentApplicant.applicant_status}}</p>
            </div>
            <div v-if="currentApplicant.guarantor">
              <label class="text-sm text-gray-500 font-500" for="status">guarantor</label>
              <p class="font-400">{{`${currentApplicant.guarantor.firstName} ${currentApplicant.guarantor.lastName}`}}</p>
            </div>
            <div v-else>
              <label class="text-sm text-gray-500 font-500" for="status">guarantor</label>
              <p
                :class="{
                  'text-red-500': currentApplicant.is_guarantor_needed
                }"
                class="font-400">{{currentApplicant.applicant_status === 'pending guarantor' ? 'pending' :
                (currentApplicant.is_guarantor_needed ? 'guarantor needed' : 'N/A')}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="my-4">
        <h2 class="flex text-base font-600 justify-between">
          occupants:
        </h2>
        <div class="w-full flex" v-if="currentApplicant.occupants.length">
          <div
            class="flex w-4/5 grid grid-cols-3 gap-4"
            v-bind:key="occupant"
            v-for="occupant in currentApplicant.occupants">
            <div>
              <label class="text-sm text-gray-500 font-500" for="name">name</label>
              <p>{{occupant.fullName}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="email">phone</label>
              <p>{{occupant.phone}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="email">email</label>
              <p>{{occupant.email}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="email">relationship</label>
              <p>{{occupant.relationship}}</p>
            </div>
          </div>
        </div>
        <div v-else class="w-full">
          <p class="text-center my-4">No data available</p>
        </div>
      </div>
      <div class="my-6">
        <h2 class="flex text-base font-600 justify-between">
          pets:
        </h2>
        <div v-if="currentApplicant.pets.length">
          <div
            class="flex w-full grid grid-cols-4 gap-4"
            v-bind:key="pet"
            v-for="pet in currentApplicant.pets">
            <div>
              <label class="text-sm text-gray-500 font-500" for="name">species</label>
              <p>{{pet.species}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500"  for="breed">breed</label>
              <p>{{pet.breed}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="name">name</label>
              <p>{{pet.name}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="type">type</label>
              <p>{{pet.type}}</p>
            </div>
          </div>
        </div>
        <div v-else-if="!currentApplicant.pets.length" class="w-full">
          <p class="text-center my-4">No data available</p>
        </div>
      </div>
      <div class="my-6">
        <h2 class="flex text-base font-600 justify-between">
          vehicles:
        </h2>
        <div v-if="currentApplicant.vehicles.length">
          <div
            class="flex w-full grid grid-cols-4 gap-4"
            v-bind:key="i"
            v-for="(vehicle, i) in currentApplicant.vehicles">
            <div>
              <label class="text-sm text-gray-500 font-500" for="name">make</label>
              <p>{{vehicle.make}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500"  for="breed">model</label>
              <p>{{vehicle.model}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="name">year</label>
              <p>{{vehicle.year}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="type">color</label>
              <p>{{vehicle.color}}</p>
            </div>
          </div>
        </div>
        <div v-else-if="!currentApplicant.vehicles.length" class="w-full">
          <p class="text-center my-4">No data available</p>
        </div>
      </div>
    </section>
    <section v-else>
      <div class="my-4">
        <div class="w-full flex">
          <div class="flex w-4/5 grid grid-cols-3 gap-4">
            <div>
              <label class="text-sm text-gray-500 font-500" for="name">name</label>
              <p class="font-400">{{`${currentApplicant.firstName} ${currentApplicant.lastName}`}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="phone">phone</label>
              <p class="font-400">{{currentApplicant.phone}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="email">email</label>
              <p class="font-400">{{currentApplicant.email}}</p>
            </div>
            <div>
              <label class="text-sm text-gray-500 font-500" for="status">guarantor status</label>
              <p class="font-400">{{currentApplicant.applicant_status}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>
<script>
export default {
  props: {
    application: {
      type: Object,
      require: true
    },
    currentApplicant: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      isEditMode: false,
    }
  },
}
</script> 