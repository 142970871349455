<template>
  <section class="w-full h-full flex flex-col flex-1 justify-between overflow-auto">
    <loader :loading="loading" :backdrop="true" />
    <div class="w-full flex flex-1 flex-col justify-between p-8">
      <div>
        <div class="flex justify-end">
          <button @click.stop="isEditMode = true" v-if="!isEditMode" class="text-blue-500 font-bold">edit</button>
        </div>
        <div class="flex grid grid-cols-2 gap-4">
          <div>
            <label class="text-sm" for="categories">application status</label>
            <dropdown
              :options="applicationStatusOptions"
              :placeholder="'select status'"
              v-if="isEditMode"
              v-model="currentApplication.application_status"
            ></dropdown>
            <p v-else class="p-2 border">{{currentApplication.application_status}}</p>
          </div>
        </div>
        <div v-if="!isEditMode" class="w-full my-4 border-t-2">
          <div class="w-full flex grid grid-cols-2 gap-2 my-4">
            <div>
              <label class="text-sm" for="phone">unit</label>
              <p>{{application.unit}}</p>
            </div>
            <div>
              <label class="text-sm" for="email">rent amount</label>
              <p>{{application.rent_amount}}</p>
            </div>
            <div>
              <label class="text-sm" for="phone">created at</label>
              <p>{{getDateValue(application.record_created)}}</p>
            </div>
            <div>
              <label class="text-sm" for="email">move in date</label>
              <p>{{getDateValue(application.move_in)}}</p>
            </div>
            <div>
              <label class="text-sm" for="email">lease term</label>
              <p>{{application.lease_term}}</p>
            </div>
            <div>
              <label class="text-sm" for="email">household' members</label>
              <p>{{households_members}}</p>
            </div>
          </div>
        </div>
        <div v-else class="w-full my-4 border-t-2">
          <div class="w-full flex grid grid-cols-1 gap-2 my-4">
            <div class="flex flex-col">
              <label class="text-sm" for="unit">unit</label>
              <input
                name="unit"
                class="p-2"
                v-model="currentApplication.unit"
                />
            </div>
            <div class="flex flex-col">
              <label class="text-sm" for="rent_amount">rent amount</label>
              <input
                name="rent_amount"
                class="p-2"
                v-model="currentApplication.rent_amount"
              />
            </div>
            <div class="flex flex-col">
              <label class="text-sm" for="record_created">created at</label>
              <input
                name="record_created"
                class="p-2"
                type="date"
                v-model="currentApplication.record_created"
                />
            </div>
            <div class="flex flex-col">
              <label class="text-sm" for="move_in">move in date</label>
              <input
                name="move_in"
                class="p-2"
                type="date"
                v-model="currentApplication.move_in"
                />
            </div>
            <div class="flex flex-col">
              <label class="text-sm" for="lease_term">lease term</label>
              <input
                name="lease_term"
                class="p-2"
                v-model="currentApplication.lease_term"
                />
            </div>
            <div class="flex flex-col">
              <label class="text-sm" for="households_members">household's members</label>
              <input
                name="households_members"
                class="p-2"
                type="numeric"
                v-model="households_members"
                />
            </div>
          </div>
        </div>
        <div class="w-full my-4 border-t-2">
          <h2 class="flex text-base font-600 justify-between mt-2">
            applicants
          </h2>
          <div class="w-full flex grid grid-cols-2 gap-2 overflow-x-hidden overflow-y-auto h-24 mt-2">
            <div
              v-bind:key="applicant"
              v-for="(applicant, i) in getAllApplicants">
              <div>
                <div
                  @click="showApplicantDetails(applicant, i)"
                  :class="{
                    'border-blue-500': i === currentApplicantIndex,
                    'text-blue-500': i === currentApplicantIndex,
                  }"
                  class="text-xs font-600 p-1 text-center border cursor-pointer">
                  {{`${applicant.firstName} ${applicant.lastName}`}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between">
        <div class="flex" v-if="applicationDocumentURL">
          <icon
            :name="'fileText'"
            class="text-blue-500 font-bold h-5 w-5 mr-2 mt-2"></icon>
          <button class="text-blue-500 underline cursor-pointer" @click="$emit('download-pdf')">view application</button>
        </div>
        <div v-if="isEditMode">
          <button
            @click.stop="updateApplicationData"
            class="text-blue-500 btn btn-primary cursor-pointer">
            save
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import moment from 'moment';
import Dropdown from "@/components/ui/Dropdown";
import Icon from '@/components/ui/Icon';
import Loader from '@/components/ui/Loader';
import ValidatorMixin from "@/components/form/ValidatorMixin";
import { mapActions, mapGetters } from 'vuex';
import EventBus from "@/utils/EventBus";
export default {
  components: { Dropdown, Icon, Loader },
  mixins: [ValidatorMixin],
  props: {
    application: {
      type: Object,
      require: true
    },
    applicationDocumentURL: {
      type: String,
      require: false
    }
  },
  computed: {
    ...mapGetters({
      getApplication: 'application_service/getApplication',
      getApplicationsList: 'application_service/getApplicationsList',
    }),
    getAllApplicants() {
      let applicants = this.application.applicants;
      const seen = new Set();
      applicants = applicants.filter(el => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
      });
      this.application.applicants.forEach(applicant => {
        if (applicant.guarantor) {
          applicants.push(applicant.guarantor);
        }
        
      });
      return applicants;
    },
    
  },
  watch: {
    isEditMode: function() {
      if (this.isEditMode) this.currentApplication = this.getApplication;
    },
    'currentApplication': function() {
      if (this.currentApplication.application_status !== this.getApplication.application_status) this.updateApplicationData();
    },
    application: function() {
      if (this.application.applicants.length) {
        this.application.applicants.forEach(applicant => {
          if (applicant.id === this.currentApplicant.id) {
            this.currentApplicant = applicant;
          }
        });
      }
      this.currentApplication = {
        ...this.application
      };
    }
  },
  data() {
    return {
      loading: false,
      isEditMode: false,
      currentApplicantIndex: 0,
      currentApplicant: null,
      currentApplication: {},
      households_members: 0,
      globalStatus: null,
      applicationStatusOptions: [
        {
          key: "not started",
          value: "not_started"
        },
        {
          key: "in progress",
          value: "in_progress"
        },
        {
          key: "submitted",
          value: "submitted"
        },
        {
          key: "accepted",
          value: "accepted"
        },
        {
          key: "declined",
          value: "declined"
        },
        {
          key: "archived",
          value: "archived"
        }
      ],
    }
  },
  emits: ['download-pdf'],
  methods: {
    ...mapActions({
      setApplication: 'application_service/setApplication',
      setApplicationsList: 'application_service/setApplicationsList'
    }),
    getGlobalStatus() {
      const applicantsLength = this.application.applicants.length;
      let currentStatusCount = {
        in_progress: 0,
        accepted: 0,
        declined: 0,
        submitted: 0,
        resident: 0,
        pending_guarantor: 0
      };
      this.application.applicants.forEach(applicant => {
        switch (applicant.applicant_status) {
          case 'identity verification':
            currentStatusCount = {
              ...currentStatusCount,
              in_progress: currentStatusCount.in_progress += 1,
            }
            if (currentStatusCount.in_progress === applicantsLength) this.globalStatus = 'in progress';
            break;
          case 'accepted':
            currentStatusCount = {
              ...currentStatusCount,
              accepted: currentStatusCount.accepted += 1,
            }
            if (currentStatusCount.accepted === applicantsLength) this.globalStatus = 'accepted';
            break;
          case 'declined':
            currentStatusCount = {
              ...currentStatusCount,
              declined: currentStatusCount.declined += 1,
            }
            if (currentStatusCount.declined === applicantsLength) this.globalStatus = 'declined';
            break;
          case 'not submitted':
            currentStatusCount = {
              ...currentStatusCount,
              in_progress: currentStatusCount.in_progress += 1,
            }
            if (currentStatusCount.in_progress === applicantsLength) this.globalStatus = 'in progress';
            break;
          case 'submitted':
            currentStatusCount = {
              ...currentStatusCount,
              submitted: currentStatusCount.submitted += 1,
            }
            if (currentStatusCount.submitted === applicantsLength) this.globalStatus = 'submitted';
            break;
          case 'resident':
            currentStatusCount = {
              ...currentStatusCount,
              resident: currentStatusCount.resident += 1,
            }
            if (currentStatusCount.resident === applicantsLength) this.globalStatus = 'resident';
            break;
          case 'pending guarantor':
            currentStatusCount = {
              ...currentStatusCount,
              pending_guarantor: currentStatusCount.pending_guarantor += 1,
            }
            if (currentStatusCount.pending_guarantor === applicantsLength) this.globalStatus = 'pending guarantor';
            break;
        
          default:
            currentStatusCount = {
              ...currentStatusCount,
              in_progress: currentStatusCount.in_progress += 1,
            }
            break;
        }
      });
      if (currentStatusCount.in_progress >= 1 && currentStatusCount.declined === 0) {
        this.globalStatus = 'in progress';
      } else if (currentStatusCount.declined >= 1) {
        this.globalStatus = 'declined';
      } else if (currentStatusCount.in_progress === 0 && currentStatusCount.declined === 0 && currentStatusCount.pending_guarantor >= 1) {
       this.globalStatus = 'pending guarantor';
      }
      this.setApplication({
        ...this.application,
        applicants_global_status: this.globalStatus
      });
      const newApplicationsList = this.getApplicationsList.map(application => {
        if (application.applicantionId === this.application.applicantionId) {
          return {
            ...this.application,
            applicants_global_status: this.globalStatus
          };
        }
        return application;
      });
      this.setApplicationsList(newApplicationsList);
    },
    getDateValue(date) {
      return moment(date).format('LL')
    },
    getDateFormateValue(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    getHouseholdsMembers(application) {
      this.households_members = application.applicants.length;
      let currentId = "0";
      if (application.applicants.length) {
        application.applicants.forEach(applicant => {
          if (applicant.role === 'applicant') {
            applicant.occupants.forEach(occupant => {
              if (occupant.id !== currentId) {
                currentId = occupant.id;
                this.households_members += applicant.occupants.length;
              }
            });
          }
        })
      }
    },
    showApplicantDetails(applicant, index) {
      this.currentApplicantIndex = index;
      this.currentApplicant = applicant;
      EventBus.emit('currentApplicantUpdated', this.currentApplicant);
    },
    updateApplicationData() {
      /* Remove with real data */
      this.loading = true;
      this.setApplication({
        ...this.currentApplication,
        applicants_global_status: this.globalStatus,
        record_created: moment(this.currentApplication.record_created)._i,
        move_in: moment(this.currentApplication.move_in)._i,
      });
      const newApplicationsList = this.getApplicationsList.map(application => {
        if (application.applicantionId === this.currentApplication.applicantionId) {
          return {
            ...this.currentApplication,
            applicants_global_status: this.globalStatus,
          };
        }
        return application;
      });
      this.setApplicationsList(newApplicationsList);
      this.isEditMode = false;
      this.currentApplication = this.getApplication;
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
  },
  beforeMount() {
    if (this.application.applicants.length)  this.currentApplicant = this.application.applicants[0];
    this.currentApplication = {
      ...this.application
    };
    this.getHouseholdsMembers(this.application);
    this.getGlobalStatus();
  }
}
</script> 