<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 justify-between overflow-auto">
      <div class="left-box-w bg-gray-100 overflow-auto">
        <application-details 
          @download-pdf="getApplicationDocument"
          :application="application"
          :applicationDocumentURL="currentApplicant?.application?.document_url"
        />
      </div>
      <div class="rigth-box-w p-8 overflow-y-auto">
        <section>
          <h2 class="flex text-blue-500 text-lg font-600 justify-between">
            {{currentApplicant.role === 'applicant' ? 'applicant information' : 'guarantor information'}}
            <icon
              @click.stop="isApplicantDetailsOpen = !isApplicantDetailsOpen"
              :class="{ 'rotate': isApplicantDetailsOpen }"
              name="chevronDown"
              class="text-blue-500 font-bold h-6 w-6"></icon>
          </h2>
          <div class="my-4">
            <div v-if="isApplicantDetailsOpen" class="w-full">
              <applicant-info :application="application" :currentApplicant="currentApplicant" />
            </div>
          </div>
        </section>
        <section>
          <h2 class="flex text-blue-500 text-lg font-600 justify-between">
            notes
            <icon
              @click.stop="isHouseholdDetailsOpen = !isHouseholdDetailsOpen"
              name="chevronDown"
              :class="{ 'rotate': isHouseholdDetailsOpen }"
              class="text-blue-500 font-bold h-6 w-6"></icon>
          </h2>
          <div v-if="isHouseholdDetailsOpen">
            <notes :application="application" />
          </div>
        </section>
        <section>
          <h2 class="flex text-blue-500 text-lg font-600 my-2 justify-between">
            portal information
            <icon
              @click.stop="isPortalInfoOpen = !isPortalInfoOpen"
              name="chevronDown"
              :class="{ 'rotate': isPortalInfoOpen }"
              class="text-blue-500 font-bold h-6 w-6"></icon>
          </h2>
          <div v-if="isPortalInfoOpen">
            <ul class="flex">
              <li
                @click="currentTabPortalInfo = 'payment'"
                :class="{ 'border-blue-500': currentTabPortalInfo === 'payment' }"
                class="pb-2 border-b-2 cursor-pointer">payments</li>
              <li
                @click="currentTabPortalInfo = 'activity'"
                :class="{ 'border-blue-500': currentTabPortalInfo === 'activity' }"
                class="px-4 pb-2 border-b-2 cursor-pointer">activity</li>
            </ul>
          </div>

          <div v-if="isPortalInfoOpen">
            <payments :application="application" v-if="currentTabPortalInfo === 'payment'" />
            <activity :data="application.records"  v-if="currentTabPortalInfo === 'activity'" />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import EventBus from '@/utils/EventBus';
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Icon from '@/components/ui/Icon'
import Loader from '@/components/ui/Loader'
import {isEqual} from "lodash-es"
import NotifyMixin from '@/mixins/NotifyMixin'
import Payments from '@/components/application_service/Payments.vue'
import Notes from '@/components/application_service/Notes.vue'
import Activity from '@/components/application_service/Activity.vue'
import ApplicantInfo from '@/components/application_service/ApplicantInfo.vue'
import ApplicationDetails from '@/components/application_service/Details.vue'
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Application',
  components: { Loader, Icon, Payments, Activity, Notes, ApplicantInfo, ApplicationDetails },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  data() {
    return {
      convertToResident: false,
      application: {},
      currentApplicant: null,
      currentTabPortalInfo: 'payment',
      isHouseholdDetailsOpen: true,
      isApplicantDetailsOpen: true,
      isPortalInfoOpen: true,
      token: '',
      account: {
        id: '',
        username: 'quextUser@TX05101252',
        password: 'Madera1234',
        client_id: 4,
        client_secret: 'XgNRCbNfllV5inyfxS0zB1vbhhsG1FSAXwQptDbN'
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      getApplication: 'application_service/getApplication',
      getApplicationsList: 'application_service/getApplicationsList'
    })
  },
  watch: {
    getApplication: function() {
      if (!isEqual(this.application, this.getApplication)) {
        this.application = this.getApplication;
        this.updateActions(this.getApplication, this.currentApplicant);
        const newApplicationsList = this.getApplicationsList.map(application => {
          if (application.applicantionId === this.application.applicantionId) {
            return {
              ...this.getApplication
            };
          }
          return application;
        });
        this.setApplicationsList(newApplicationsList);
      }
    },
    currentApplicant: function() {
      if (this.currentApplicant && this.currentApplicant.is_guarantor_needed && !this.currentApplicant.guarantor){
        this.updateActions(this.getApplication, this.currentApplicant);
      }
    }
  },
  methods: {
    ...mapActions({
      setApplication: 'application_service/setApplication',
      setApplicationsList: 'application_service/setApplicationsList',
      setApplicant: 'application_service/setCurrentApplicant',
      addAction: 'actions/addAction',
      removeAction: 'actions/removeAction',
    }),
    handleConvertToResident() {
      const newApplication = {
        ...this.application,
        applicants: this.application.applicants.map(applicant => {
          if (applicant.id === this.currentApplicant.id) {
            this.currentApplicant = {
              ...applicant,
              applicant_status: 'resident'
            };
            return {
              ...applicant,
              applicant_status: 'resident'
            }
          }
          return applicant;
        })
      }
      this.notifySuccess(`${this.currentApplicant.firstName} ${this.currentApplicant.lastName} successfully became a resident`);
      this.application = newApplication;
      this.setApplication(newApplication);
    },
    handleRemoveApplicant() {
      if (this.application.applicants.length > 1) {
        const newApplication = {
          ...this.application,
          applicants: this.application.applicants.filter(applicant => applicant.id !== this.currentApplicant.id)
        }
        const newApplicationsList = this.getApplicationsList.map(application => {
        if (application.applicantionId === newApplication.applicantionId) {
          return {
            ...newApplication
          };
        }
        return application;
      });
      this.setApplicationsList(newApplicationsList);
        this.notifySuccess(`${this.currentApplicant.firstName} ${this.currentApplicant.lastName} was successfully removed`);
        this.application = newApplication;
        this.currentApplicant = newApplication.applicants[0];
        this.setApplication(newApplication);
      } else {
        this.notifyError("you can't remove the only applicant of this application.");
      }
    },
    handleArchiveApplication() {
      const newApplicationsList = this.getApplicationsList.map(application => {
        if (application.applicantionId === this.application.applicantionId) {
          return {
            ...this.application,
            application_status: 'archived'
          };
        }
        return application;
      });
      this.setApplicationsList(newApplicationsList);
      this.setApplication({
        ...this.application,
        application_status: 'archived'
      });
      this.application = {
        ...this.application,
        application_status: 'archived'
      };
      this.$router.push({ name: 'manage_application.index' });
      this.notifySuccess('the application was successfully archived');
    },
    updateActions(application, applicant) {
      if (application && application.applicants_global_status === 'resident'){
        this.addAction({
          routeName: this.$route.name,
          item: {
            id: 'create-lease',
            title: 'create lease',
            showConfirmation: true,
            confirmationType: 'success',
            confirmationMessage: 'are you sure you want to create a lease for this application?',
            confirmBtnText: 'yes, create',
            cancelBtnText: 'no, cancel',
          },
        });
      } else {
        this.removeAction({
          routeName: this.$route.name,
          item: {
            id: 'create-lease',
          },
        });
      }
      if (applicant && applicant.is_guarantor_needed) {
        this.addAction({
          routeName: this.$route.name,
          item: {
            id: 'add-guarantor',
            title: 'add guarantor',
            routeName: 'manage_application.add_person',
            params: { role: 'guarantor' }
          },
        });
      } else {
        this.removeAction({
          routeName: this.$route.name,
          item: {
            id: 'add-guarantor',
          },
        });
      }
    },
    setCurrentApplicant(applicant) {
      this.currentApplicant = applicant;
      this.setApplicant(this.currentApplicant);
    },
    getAuthBlueMoonToken() {
      this.loading = true;
      
      this.$blueMoonServiceDataProvider
      .create('authTokenBM', {
            // REPLACE THIS WITH REAL DATA
            data: {
              username: this.account.username,
              password: this.account.password,
              scope: 'full',
              grant_type: 'password',
              client_id: this.account.client_id,
              client_secret: this.account.client_secret,
          },
      })
      .then(async (res) => {
        this.token = res.access_token;
        this.loading = false;
      })
      .catch(() => {
          this.loading = false;
          this.notifyError('something went wrong. please try again.');
      });
    },
    downloadProcess(data, extentionFile) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `application-document.${extentionFile}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getApplicationDocument() {
      this.loading = true;

      const config = {
        method: 'get',
        url: 'https://api.bluemoonforms.com/application/api/application/pdf/9008734',
        headers: { 
          'Authorization': `Bearer ${this.token}`
        },
        responseType: 'blob'
      };

      axios(config)
      .then(async (response)  => {
        this.downloadProcess(response.data,'pdf')
        this.loading = false;
      })
      .catch(() => {
        this.notifyError('something went wrong. please try again.');
        this.loading = false;
      });

    },
  },
  validations: {
    text: 'required | is_empty',
    'optionalData.category': 'required',
    'optionalData.intent': 'required',
    'optionalData.subIntent': 'required',
  },
  created() {
    this.initValidator()
    this.validator.extend('is_empty', function(value) {
      return !value.match(/^\s+$/)
    })
    this.validator.setCustomErrorMessages({
      'text.is_empty': 'this field is empty.',
    })
    if (!this.community)
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
    EventBus.on('set-applicant', (payload) => {
      this.currentApplicant = payload;
    });
    EventBus.on('confirm-covert-resident', () => {
      this.handleConvertToResident();
    });
    EventBus.on('confirm-remove-applicant', () => {
      this.handleRemoveApplicant();
    });
    EventBus.on('confirm-archive-application', () => {
      this.handleArchiveApplication();
    });
    EventBus.on('currentApplicantUpdated', (applicant) => {
        this.currentApplicant = applicant;
    });
    this.getAuthBlueMoonToken();
  },
  beforeMount() {
    if (this.getApplication) {
      this.currentApplicant = this.getApplication.applicants[0];
      this.setApplicant(this.currentApplicant);
      this.application = this.getApplication;
      this.updateActions(this.application, this.currentApplicant);
    }
  },
  unmounted() {
    EventBus.off('confirm-covert-resident');
  },
}
</script>
<style scoped>
.left-box-w{
  width: 30%;
}
.rigth-box-w{
  width: 70%;
}
.color-text {
  color: var(--highlightColor500);
}
.button-color {
  border-color: var(--highlightColor500);
  color: var(--highlightColor500);
}
.isDisabled {
  @apply text-gray-400;
}
.rotate {
  transform: rotate(180deg);
}
</style>