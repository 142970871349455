<template>
  <section>
    <div
    v-bind:key="note"
    v-for="note in notes">
      <div class="w-full border-2 p-2 my-2">
        <div class="flex">
          <p class="mr-4">{{getDateValue(note.date)}}</p>
          <p>{{note.author}}</p>
        </div>
        <div>
          <p>{{note.note}}</p>
        </div>
      </div>
    </div>
    <div class="items-center" v-if="isNewNoteFormOpen">
      <div class="border">
        <div class="flex flex-col justify-between">
          <div class="flex">
            <p class="mr-4">{{getDateValue(new Date())}}</p>
            <p>{{currentUserName}}</p>
          </div>
          <div class="mt-2">
            <textarea
              id="noteText"
              name="noteText"
              placeholder="type note..."
              class="resize-none w-full focus:outline-none p-2 bg-white"
              rows="3"
              v-model="newNote"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isNewNoteFormOpen" class="flex w-24 cursor-pointer">
      <icon
        name="plus"
        class="text-blue-500 font-bold mt-6 h-5 w-4 mr-2"></icon>
        <button
        @click="isNewNoteFormOpen = true"
        class="my-4 border-b-2 border-blue-500">

        add note
      </button>
    </div>
    <div v-else>
      <button
        @click="addNewNote"
        class="my-4 border-b-2 border-blue-500">

        save
      </button>
    </div>
  </section>
</template>
<script>
import AuthMixin from '@/components/auth/AuthMixin'
import moment from 'moment';
import Icon from '@/components/ui/Icon'
export default {
  components: { Icon },
  mixins: [AuthMixin],
  props: {
    application: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      currentUserName: '',
      notes: [],
      isNewNoteFormOpen: false,
      newNote: ''
    };
  },
  methods: {
    getDateValue(date) {
      return moment(date).format('LL');
    },
    addNewNote() {
      const note = {
        date: new Date(),
        author: this.currentUserName,
        note: this.newNote
      };
      this.notes.push(note);
      this.isNewNoteFormOpen = false;
    }
  },
  beforeMount() {
    if (this.profile) this.currentUserName = `${this.profile.firstName} ${this.profile.lastName}`
    if (this.application) this.notes = this.application.notes; 
  }
}
</script>